import React,{useState,useEffect} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './CardBoxVideoLive.css'
import MindPayment from '../PaymentIntent/MindPayment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface liveVideoInfo{
    title:string;
    enable:boolean;
    date:string;
    time:string;
    imgUrl:string;
    zoomLink:string;
    
}

const CardBoxVideoLive = (Info:liveVideoInfo) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const formattedCurrentDate = `${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`;
    console.log(formattedCurrentDate)
    console.log("current ifnof "+ Info.date)
    if (formattedCurrentDate === Info.date) {
      setActive(true);
      console.log("teah")
    }
  }, [Info.date]);
 
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Card sx={{ minWidth: 295, minHeight:200, position:'relative' }}>
    <CardMedia
        sx={{ height: 200 }}
        image={Info.imgUrl ? Info.imgUrl:"../../assets/images/Portadas/Video-P-1.jpg"}
        title=""
      />
      <CardContent className="card-content-live absolute w-full h-full z-10 top-0 flex flex-col justify-between items-center bg-slate-100 bg-opacity-40">
        <div className="w-full">
            <h4 className='text-sc-c2 font-[MADE-Bon-Voyage] text-4xl'>LIVE</h4>
            <h5 className='text-sc-c2 font-[DIN-Condensed] font-bold text-2xl'>{Info.date} {Info.time}</h5>
        </div>
        {Info.enable ? (
            <Link to={active ? Info.zoomLink:'#'}>
                <Button className={`button-30 px-2 w-fit h-[25px] ${active ? '!text-sc-c2':''}`} disabled={!active}>Unirse</Button>
            </Link>
        ):(
            <button className='button-29 px-2 w-fit h-[25px]'>Regístrate</button>
        )}
      </CardContent>
       <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <MindPayment/>
        </DialogContent>
      </Dialog>
    </Card>
  )
}

export default CardBoxVideoLive