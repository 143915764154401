import React,{useState,useEffect} from 'react'
import './LoginPage.css'
import { useDispatch, useSelector } from 'react-redux';
import { login, requestResetPassword } from "../../../services/user";
import { restartUser } from "../../../redux/userRedux";
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loader from '../../../components/Loaders/Loader';
import CircularProgress from "@mui/material/CircularProgress";


const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90vw',
  maxWidth:'600px',
  color:'white',
  bgcolor: " rgba( 84, 152, 156, 0.85 )",
  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  border: "1px solid rgba( 255, 255, 255, 0.18 )",
  p: 2,
  borderRadius: 5,
  backdropFilter:'blur(4px)'
};

const LoginPage = () => {
  const[errorMessage,setErrorMessage] = useState(false);
  const[requestNewPasswordSuccess,setRequestNewPasswordSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [emailReset, setEmailReset] = useState("");
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { isFetching, error } = useSelector(state => state.user);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(restartUser());
  },[])

  const handleLogin = (e) => {
    try{
        e.preventDefault();
      login(dispatch, { email, password });
    
      if(!error){
          navigate("/index");
      }

    }catch(error){
      console.log(error);
    }
    
  };

  const handleResetPassword = async(email)=>{
    try {
      const response = await requestResetPassword(dispatch, email);
      if (response === "Password reset email sent") {
        setRequestNewPasswordSuccess(true);
      }else{
        alert("No se encontró el Usuario")
      }
      console.log(response);

    } catch (error) {
      console.log(error);
    }
    

  };

  return (
    <>
      <section className="login-section">
        <div className="text-box flex-col gap-6 justify-start items-start pl-20 w-full md:w-1/2 font-[MADE-Bon-Voyage]">
          <h1 className="text-sc-c2">SAR ASANAS</h1>
          <div className="text-content">
            <h6 className="text-sc-c2">
              “La vida es como el yoga, una práctica de todos los días”.
            </h6>
          </div>
        </div>
        <div className="form w-full md:w-1/2 flex justify-center">
          <div className="w-11/12 md:w-full form-box flex flex-col justify-center items-center gap-5">
            <input
              type="email"
              className="text"
              name="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage(false);
              }}
              placeholder="Correo Electrónico"
            />
            <FormControl sx={{ m: 1, width: '100%',backgroundColor: 'white'  }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="flex flex-col gap-5">
              <button
                type="submit"
                className="w-full button-29"
                onClick={handleLogin}
                disabled={isFetching}
              >
                {isFetching ? (
                  <div
                    role="status"
                    className="flex items-center justify-center"
                  >
                    <CircularProgress color="inherit" size={25} />
                  </div>
                ) : (
                  "Ingresar"
                )}
              </button>
              <div className="flex flex-col gap-1">
                <div className="" onClick={() => handleOpen()}>
                  <p className="text-sb-100 font-[DIN-Condensed] text-lg hover:font-bold cursor-pointer">
                    Olvidaste tu contraseña?
                  </p>
                </div>
                <div className="" onClick={() => navigate("/registro-trail/free")}>
                  <p className="text-sb-900 font-[DIN-Condensed] text-lg text-center hover:font-bold cursor-pointer">
                    No Tengo Usuario
                  </p>
                </div>
              </div>
              {error && (
                <Alert severity="error">Los datos son incorrecto</Alert>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {requestNewPasswordSuccess ? (
          <Box sx={modalStyle}>
            <div className="w-full flex justify-center">
              <img
                src="../../assets/icons/Logo/Logo-sm.png"
                width={64}
                alt="Logo"
                className="invert brightness-0"
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
            >
              Se ha enviado un correo al {emailReset} con el link para
              restablecer tu constraseña
            </Typography>
          </Box>
        ) : (
          <Box sx={modalStyle}>
            <div className="w-full flex justify-center">
              <img
                src="../../assets/icons/Logo/Logo-sm.png"
                width={64}
                alt="Logo"
                className="invert brightness-0"
              />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Restablecer Contraseña
            </Typography>
            <Typography id="modal-modal-title" variant="p" component="p">
              Te enviaremos un correo con el cual podras restablecer tu
              contraseña
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <input
                type="email"
                className="text w-full p-3 text-sb-900"
                name="Email"
                onChange={(e) => {
                  setEmailReset(e.target.value);
                  setErrorMessage(false);
                }}
                placeholder="Correo Electronico"
              />
            </Typography>
            <div className="flex w-full justify-center p-2">
              <Button
                onClick={() => handleResetPassword(emailReset)}
                className="button-30 w-fit p-2"
                variant="contained"
                disabled={emailReset === ""}
              >
                {isFetching ? <Loader /> : "Restablecer"}
              </Button>
            </div>
          </Box>
        )}
      </Modal>
    </>
  );
}

export default LoginPage


