import React, {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import GridGallery from '../../components/Gallery/GridGallery';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';
import RatingBox from '../../components/CardsBox/RatingBox';
import './SubscriptionPage.css'
import SliderCardsPrograms from '../../components/Sliders/SliderCardsPrograms';
import SubscriptionBox2 from '../../components/CardsBox/SubscriptionBox2';

const SubscriptionPage = () => {

  useEffect(() =>{
    AOS.init();
  })

  return <>
  <NavBar/>

  <section>
  <h2 className='uppercase text-center text-sb-800 font-[MADE-Bon-Voyage] p-10 text-4xl md:text-6xl'>Membresias</h2>
      <div className="flex flex-wrap justify-center items-start gap-5 md:gap-10">
              <SubscriptionBox2
                title='Básica'
                subtitle='Subscripción'
                price={333}
                color='bg-so-600'
                desc={[
                  'Acceso a las biblioteca con más de 50 clases grabadas con diferente duración y diferentes estilos como: Vinyasa, Mandala Vinyasa, Power Yoga, African Mix, Yoga Restaurativo, Yoga Suave.', 
                  'Nuevo contenido cada mes.'
                ]}
                stripe='https://buy.stripe.com/3csfZDh2v61N03CaEO'
                thumbnail='https://storage.googleapis.com/video-storage-sarasanas/Series/Membresia-Basica.webp'
              />

              <SubscriptionBox2
                title='Premium'
                subtitle='Subscripción'
                price={555}
                color='bg-sb-600'
                desc={[
                  "Acceso a la biblioteca con más de 50 clases grabas.",
                  "Acceso directo a los retos pasados y nuevos.",
                  "Acceso al grupo semanal de martes de meditación y a las meditaciones grabadas.",
                  "Comunidad privada de motivación y acompañamiento por whatsapp.",
                  "2 Clases en vivo mensuales.",
                  "Nuevo contenido cada mes.",
                  "Contenido y dinámicas especiales del mes y exclusivas con Sar.",
                  "Precios y descuentos especiales para eventos."
                ]}
                stripe='https://buy.stripe.com/7sI8xb6nRbm73fO28j'
                thumbnail='https://storage.googleapis.com/video-storage-sarasanas/Series/Membresia-Premium.webp'
              />
               <SubscriptionBox2
                title='Conexión'
                subtitle='Subscripción'
                price={333}
                color='bg-so-600'
                desc={[
                  "⁠Tu espacio seguro y sagrado para conectar contigo a través de diferentes ejercicios prácticos, herramientas energéticas y meditaciones sencillas nutrirán tu autoconocimiento, desarrollo personal y poder interior.",
                  "Todos los martes por zoom 9pm (cdmx)",
                  "Sesiones de 30/ 40 minutos semanales que se quedan grabadas",
                  "No estas solo, nos acompañamos y sostenemos en TRIBU",
                  "Acceso a más de 30 meditaciones grabadas del año anterior",
                ]}
                stripe='https://buy.stripe.com/cN214Jh2v3TF8A8bIW'
                thumbnail='https://storage.googleapis.com/video-storage-sarasanas/Series/conexion-thumb.webp'
              />
      </div>
  </section>
  <section>
    <h2 className='uppercase text-center text-sb-800 font-[MADE-Bon-Voyage] p-10  text-4xl md:text-6xl'>Programas</h2>
    <div className="right-content w-full">
            <SliderCardsPrograms/>
    </div>
  </section>
  <section className="rating-section p-20">
    <div className='mandala-image absolute right-[-250px] opacity-5  mt-32'>
          <img src={require("../../assets/icons/Mandala/mandala-8.png")} alt=""/>
    </div>
    <h2 className='uppercase text-center text-sb-800 font-[MADE-Bon-Voyage] p-10'>Se Parte de la Tribu</h2>
    <div className="rating-boxes box flex flex-wrap justify-center items-center gap-10 z-10">
      <RatingBox name='Marce L' rating={5} description='100% recomiendo tu trabajo y tus ganas!! Eres lo máximo, eres muy coherente en lo que practicas y tu forma de ser ❤️'/>
      <RatingBox name='Andrea R' rating={5} description='Disfrute muchísimo cada práctica, me llenaron de una energía súper bonita y me encanta que no solo es la práctica por cumplir el reto, si no que cada una se intenciona y al final todo se unió 🤍 GRACIAS SARA'/>
      <RatingBox name='Giliano' rating={5} 
      description='Claro que recomiendo este reto. Fue un disfrute practicar cada clase y la intención puesta en cada una de ellas. También me encantó la música, la energía de cada clase. Muchas gracias Saris 💛'/>
      <RatingBox name='Mafer' rating={5} 
      description='Me llevo la conexión con mi cuerpo, con mi mente y los momentos de quietud que me permiten equilibrar mi día e integrar lo que estoy pasando para sentirlo todo.  Con total certeza recomiendo tus clases, porque más que un reto físico es una transformación en la que al llevar la intención del tapete al exterior la vida se vive más suave.
En verdad, gracias por tu luz, tu guía, tu contención y tú atención.'/>
      <RatingBox name='Lupita M' rating={5} description='Me siento feliz de haber aprendido a darle más consciencia y presencia a mis días con la práctica constante 🙌
Gracias Sara por poner tu corazón en todo lo que haces y compartirnos prácticas tan maravillosas 🫶 Inspiras 🤍'/>
    </div>
    <div className='mandala-box absolute left-[-170px] opacity-5  mb-32'>
          <img src={require("../../assets/icons/Mandala/mandala-8.png")} alt=""/>
        </div>
  </section>
    <section className="gallery">
    <GridGallery/>
  </section>

    {/* <section className='social-section'>
        <SocialMediaBox/>
      </section>*/} 
      <Footer/>
  
  </>
}

export default SubscriptionPage;